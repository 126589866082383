import _ from 'lodash';
import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

import * as constants from '../constants';
import { getAllMembersPagesRefs } from '../services/pages';
import { getController } from '../wrappers/controllers';
import { getPageData } from '../wrappers/pages';
import { isInMembersAreaSubPage } from '../pages';
import { log } from '../../utils/monitoring';
import { getAllCompsByApplicationId } from '../wrappers/tpa';
import {
  addSubPagesMenu,
  getSOSPContainerRef,
  getComponentLayout,
  updateComponentLayout,
  removeComponent,
  getSOSPProfileCardComponentRef,
  fixSOSPHeightForVerticalLayout,
  getById,
  getComponentChildren,
  updateFullStyle,
} from '../wrappers/components';
import * as layoutsService from '../services/layouts';

async function verifySOSPColor({
  editorSDK,
  isHorizontal,
  sospContainerRef,
}: {
  editorSDK: EditorSDK;
  isHorizontal: boolean;
  sospContainerRef: ComponentRef;
}) {
  const sospStyle = await editorSDK.components.style.get('', { componentRef: sospContainerRef });
  const alpha = sospStyle.style.properties['alpha-bg'];
  const bgColor = sospStyle.style.properties.bg;

  if (isHorizontal && !(alpha === 1 && bgColor === 'color_11')) {
    log('The color of SOSP did not change properly after relayout when changing to horizontal layout', {
      tags: { isHorizontal },
      extra: { alpha, bgColor },
    });
  }

  if (!isHorizontal && !(alpha === 0 && bgColor === 'color_1')) {
    log('The color of SOSP did not change properly after relayout when changing to vertical layout', {
      tags: { isHorizontal },
      extra: { alpha, bgColor },
    });
  }
}

async function relayoutSOSP({
  editorSDK,
  sospContainerRef,
  pwComponentRef,
  isHorizontal,
}: {
  editorSDK: EditorSDK;
  sospContainerRef: ComponentRef;
  pwComponentRef: ComponentRef;
  isHorizontal: boolean;
}) {
  const sospLayout = _.cloneDeep(
    isHorizontal ? constants.SOSP_CONTAINER_HORIZONTAL.layout : constants.SOSP_CONTAINER.layout,
  );
  const sospStyle = _.cloneDeep(
    isHorizontal ? constants.SOSP_CONTAINER_HORIZONTAL.style : constants.SOSP_CONTAINER.style,
  );
  const headerRef = await editorSDK.siteSegments.getHeader('');
  const headerLayout = await getComponentLayout({ editorSDK, componentRef: headerRef });
  sospLayout.y += headerLayout.height;

  await Promise.all([
    updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: sospLayout }),
    // @ts-ignore - Either incorrect types in platform editor sdk or an issue when updating sosp styles
    updateFullStyle({ editorSDK, componentRef: sospContainerRef, style: sospStyle }),
  ]);

  // MA-390 investigation
  verifySOSPColor({ editorSDK, isHorizontal, sospContainerRef });

  // Workarounding layouting issues in document services.. need to trigger it multiple times to sit correctly
  setTimeout(
    () => updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { y: sospLayout.y } }),
    0,
  );
  setTimeout(
    () => updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { height: sospLayout.height } }),
    0,
  );

  // Layout vertical sidebar with proper margins
  if (!isHorizontal) {
    await fixSOSPHeightForVerticalLayout({ editorSDK, pwComponentRef, sospContainerRef });
  }
}

// Deleting other than PW components (including menus) in SOSP to avoid layout issues
async function clearSOSPContainer({
  editorSDK,
  pwComponentRef,
  sospContainerRef,
}: {
  editorSDK: EditorSDK;
  pwComponentRef: ComponentRef;
  sospContainerRef: ComponentRef;
}) {
  const sospChildrenRefs = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const unexpectedComponentsRefs = sospChildrenRefs.filter((comp) => !_.isEqual(comp, pwComponentRef));
  await Promise.all(unexpectedComponentsRefs.map((componentRef) => removeComponent({ editorSDK, componentRef })));
}

async function addMenuToSOSP({
  editorSDK,
  isHorizontal,
  sospContainerRef,
}: {
  editorSDK: EditorSDK;
  isHorizontal: boolean;
  sospContainerRef: ComponentRef;
}) {
  const controllerRef = await getController(editorSDK);
  // TODO: Possible issue - controllerRef type mismatch
  await addSubPagesMenu(editorSDK, constants.MENU_IDS.SUB_MENU_ID, sospContainerRef, controllerRef!, isHorizontal);
}

async function relayoutPW({
  editorSDK,
  pwComponentRef,
  isHorizontal,
}: {
  editorSDK: EditorSDK;
  pwComponentRef: ComponentRef;
  isHorizontal: boolean;
}) {
  const layout = isHorizontal ? constants.PW_HORIZONTAL_LAYOUT : constants.PW_VERTICAL_LAYOUT;
  await updateComponentLayout({ editorSDK, componentRef: pwComponentRef, layout });
}

async function getAllMAPagesTPASectionsComponents({ editorSDK }: { editorSDK: EditorSDK }) {
  const allPagesRefs = await getAllMembersPagesRefs({ editorSDK });
  const allPagesDatas = await Promise.all(allPagesRefs.map((pageRef) => getPageData({ editorSDK, pageRef })));
  const allVerticalsPagesDatas = allPagesDatas.filter(
    (pageData) => pageData.tpaApplicationId && pageData.tpaApplicationId > 0,
  );
  const allCustomPagesDatas = allPagesDatas.filter(
    (pageData) => typeof pageData.tpaApplicationId === 'undefined' || pageData.tpaApplicationId === 0,
  );

  const allCustomPagesIds = allCustomPagesDatas.map((pageData) => pageData.id);
  const allApplicationsIds = allVerticalsPagesDatas.map((pageData) => pageData.tpaApplicationId);
  const [allCustomPagesCompRefs, allApplicationsComponents] = await Promise.all([
    Promise.all(allCustomPagesIds.map((id) => getById({ editorSDK, id }))),
    Promise.all(
      allApplicationsIds.map((applicationId) =>
        // TODO: Possible issue - applicationId type mismatch
        getAllCompsByApplicationId({ editorSDK, applicationId: applicationId! }),
      ),
    ),
  ]);

  const allApplicationsComponentsFlat = allApplicationsComponents.reduce((acc, comps) => [...acc, ...comps], []);
  const onlyMAPagesComponents = allApplicationsComponentsFlat.filter(
    (comp) => allPagesDatas.map((data) => data.id).indexOf(comp.pageId) > -1,
  );

  const [allCustomPagesComponents, allVerticalsPagesComponentsRefs] = await Promise.all([
    Promise.all(allCustomPagesCompRefs.map((componentRef) => getComponentChildren({ editorSDK, componentRef }))),
    Promise.all(onlyMAPagesComponents.map((comp) => getById({ editorSDK, id: comp.id }))),
  ]);

  const allCustomPagesComponentsFlat = allCustomPagesComponents.reduce((acc, comps) => [...acc, ...comps], []);

  return { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat };
}

async function maybeUpdateCustomPageComponentLayout({
  editorSDK,
  componentRef,
  isHorizontal,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  isHorizontal: boolean;
}) {
  const componentLayout = await getComponentLayout({ editorSDK, componentRef });

  // If components are out of main section, don't do anything with them
  if (componentLayout.x < 0 || componentLayout.x > constants.CLASSIC_EDITOR_MAIN_SECTION_WIDTH) {
    return;
  }

  // Move components left and down if layout is being changed to horizontal
  const diffX = constants.SECTION_DEFAULT_LAYOUT.x - constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.x;
  const diffY = constants.SECTION_DEFAULT_LAYOUT.y - constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.y;

  const newLayout = { ...componentLayout };
  if (isHorizontal) {
    newLayout.x = componentLayout.x - diffX;
    newLayout.y = componentLayout.y - diffY;
  } else {
    newLayout.x = componentLayout.x + diffX;
    newLayout.y = componentLayout.y + diffY;
  }

  await updateComponentLayout({ editorSDK, componentRef, layout: newLayout });
}

async function relayoutMASections({ editorSDK, isHorizontal }: { editorSDK: EditorSDK; isHorizontal: boolean }) {
  const { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat } = await getAllMAPagesTPASectionsComponents({
    editorSDK,
  });
  const verticalsComponentsLayout = isHorizontal
    ? constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL
    : constants.SECTION_DEFAULT_LAYOUT;

  const allPromises = [
    ...allVerticalsPagesComponentsRefs.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: verticalsComponentsLayout }),
    ),
    ...allCustomPagesComponentsFlat.map((componentRef) =>
      maybeUpdateCustomPageComponentLayout({ editorSDK, componentRef, isHorizontal }),
    ),
  ];

  return Promise.all(allPromises);
}

async function relayoutMA({ editorSDK, isHorizontal }: { editorSDK: EditorSDK; isHorizontal: boolean }) {
  const isInMembersArea = await isInMembersAreaSubPage(editorSDK);

  if (!isInMembersArea) {
    return;
  }

  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  const pwComponentRef = await getSOSPProfileCardComponentRef({ editorSDK });

  if (!pwComponentRef) {
    return;
  }

  // Not in parallel because editor struggles to handle this relayouting
  // Must layout SOSP last as otherwise it can be weirdly stretched by the components inside
  await clearSOSPContainer({ editorSDK, sospContainerRef, pwComponentRef });
  await relayoutPW({ editorSDK, pwComponentRef, isHorizontal });
  await addMenuToSOSP({ editorSDK, sospContainerRef, isHorizontal });
  await relayoutSOSP({ editorSDK, sospContainerRef, pwComponentRef, isHorizontal });
  await relayoutMASections({ editorSDK, isHorizontal });
}

function setHorizontalLayout(editorSDK: EditorSDK) {
  return relayoutMA({ editorSDK, isHorizontal: true });
}

function setSidebarLayout(editorSDK: EditorSDK) {
  return relayoutMA({ editorSDK, isHorizontal: false });
}

async function setHorizontalPWHeight(editorSDK: EditorSDK, newHeight: number) {
  const { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat } = await getAllMAPagesTPASectionsComponents({
    editorSDK,
  });
  const isHorizontal = await layoutsService.isMyAccountLayoutHorizontal({ editorSDK });

  if (!isHorizontal) {
    return;
  }

  const menuHeight = 40;
  const newSectionYPosition = newHeight + menuHeight;
  const newSospHeight = newSectionYPosition;

  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  await updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { height: newSospHeight } });

  const allPromises = [
    ...allVerticalsPagesComponentsRefs.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: { y: newSectionYPosition } }),
    ),
    ...allCustomPagesComponentsFlat.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: { y: newSectionYPosition } }),
    ),
  ];

  return Promise.all(allPromises);
}

// This doesn't support app widgets yet, need to make sure this also works with app widgets when enabling them
export { setHorizontalLayout, setSidebarLayout, setHorizontalPWHeight };
